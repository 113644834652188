export default {
  actions: {
    label: 'Action',
    disabledActionLabel: `Vous ne pouvez pas faire d'actions dans un poste que vous ne suivez pas.`,
    disabledActionLabelNotLookingForWork:
      "Ce candidat n'est pas présentement à la recherche d'emploi, et donc ne peut pas être contacté.",
    adHoc: {
      label: `Préparer l'entrevue sur demande`,
      confirmationModal: {
        header: `Préparer l'entrevue sur demande`,
        title: 'Titre',
        interviewType: {
          video: 'Video',
          inPerson: 'In person',
          phone: 'Phone',
        },
        to: 'à',
        startTime: 'Temps de début',
        endTime: 'Temps de fin',
        addCollaborators: 'Inviter des co-intervieweurs',
        add: 'Ajouter',
        timeZonePlaceholder: 'Choisir le fuseau horaire',
        emailPlaceholder: 'Entrer une adresse courriel',
        cancelButton: 'Annuler',
        sendButton: `Envoyer l'invitation`,
        titleRequired: 'Vous devez entrer un titre',
        appointmentTypesRequired: `Vous devez sélectionner au moins un type d'entrevue`,
        startDateRequired: 'Vous devez choisir une date',
        timeZoneRequired: 'Vous devez choisir un fuseau horaire',
        startTimeRequired: 'Vous devez entrer une heure de début',
        endTimeRequired: 'Vous devez entrer une heure de fin',
        endTimeGreater: `L'heure de fin doit être après l'heure de début`,
        invalidEmail: `L'adresse courriel doit être valide`,
        interviewExist: 'Le candidat a déjà passé un entretien actif',
        interviewLocationRequired: `Vous devez entrer un emplacement pour l'entrevue`,
        interviewLocation: `Emplacement de l'entrevue`,
      },
    },
    meet: {
      interviewLabel: `I'entrevue`,
      label: `Demande d'entrevue`,
      confirmationModal: {
        header: "Requête d'entrevue {{type}} de {{duration}} min.",
        body: 'Le candidat verra une sélection de plages horaires disponibles pour une entrevue.',
        cancelButton: 'Annuler',
        sendButton: 'Envoyer',
      },
      notificationModal: {
        header: 'Pas de disponibilité pour une interview avec un recruteur',
        body:
          'Pour inviter un candidat à une interview , au moins un recruteur de votre organisation doit être disponible dans le calendrier AppyHere.',
        cancelButton: 'Annuler',
        createButton: 'Ajouter une disponibilité',
      },
    },
    dismiss: {
      label: 'Rejeter',
      confirmationModal: {
        header: 'Rejeter ce candidat?',
        body: 'Ce candidat ne sera pas considéré pour ce poste.',
        dismissalReasonEnabledBody: 'Ce candidat ne sera pas considéré pour ce poste. Veuillez choisir la raison du rejet:',
        cancelButton: 'Annuler',
        sendButton: 'Confirmer',
        others: 'Autre(s) raison(s)',
        errorMsg: 'Vous devez sélectionner un motif de réjection.',
        reasonForDismissal: 'Spécifiez la/les raison(s) du rejet',
      },
    },
    undismiss: {
      label: 'Renverser la rejection du candidat',
      confirmationModal: {
        header: 'Renverser la rejection de ce candidat',
        body: 'Ce candidat ne sera plus considéré rejeté, et sera retourné à la liste de candidats actifs.',
        cancelButton: 'Annuler',
        sendButton: 'Confirmer',
      },
    },
    unDismissButton: 'Annuler le rejet',
    backgroundCheck: {
      label: 'Vérification des antécédents',
      confirmationModal: {
        header: 'Contactez-nous pour accéder aux vérifications des antécédents!',
        body: `Pour plus de renseignements sur l'ajout des vérifications des antécédents à votre compte, vous pouvez nous contacter directement par courriel.`,
        sendButton: 'Ok',
      },
    },
    hire: {
      startDate: 'Date de début',
      modalMessage: 'Note',
      hireDetailsDesc1: 'Le candidat a été embauché.',
      dateOfJoining: 'Première date de disponibilité du candidat',
      hireOrReject: 'Embauche / Refus',
      unhire: 'Désembaucher',
      offerMade: 'Faire une offre',
      reoffer: 'Envoyer nouvelle offre',
      label: 'Embaucher',
      sendMessageToCandidates: "Envoyer l'offre au candidat",
      sendMessageToCandidatesToolTip: `Cochez cette case pour que l'offre soit envoyé au candidat. Le candidat pourra ainsi ajuster leur première date de disponibilité.`,
      autoDismissCandidates: `Rejeter automatiquement le candidat s'il refuse l'offre`,
      autoDismissCandidatesToolTip: `Cochez cette case pour rejeter le candidat s'il refuse l'offre.`,
      confirmationModal: {
        salary: 'Salaire',
        hourly: 'Horaire',
        annually: 'Annuel',
        annual: 'Annuel',
        custom: 'Spécial',
        salaryValue: 'Montant du salaire',
        customUnit: 'Unité spéciale',
        header: `Saisir les détails salariales de l'offre`,
        body: 'Ajoute des détails',
        cancelButton: 'Annuler',
        sendButton: 'Soumettre',
        saveButton: 'Sauvegarder',
        text1: `L'offre salariale proposée initialement est `,
        text2: `de l'heure`,
        candidateHiringForm: 'Candidate Hiring Form',
        selectHireFormType: "Choisir le type de formulaire d'embauche",
        hireFormType: 'Hire Form Type',
        contractStartDate: `Première date de disponibilité du candidat`,
        contractEndDate: `Fin de contrat`,
        contractDetailsWording: `Conditions`,
        contractDetailsWordingPlaceholder: `Entrer la description du contrat`,
        contractTerms: ``,
        contractWordingHintMessage: `Le candidat utilise la langue anglaise dans l'application.`,
        contractDetailsWordingTooltip: `Ces conditions seront incluses dans le formulaire envoyé au candidat.`,
        candidateStatus: `Candidate's Status`,
        partTime: 'Temps partiel',
        fullTime: 'Temps plein',
        storeNumber: 'Numéro de succursale',
        unitRequired: `L'unité est requise`,
        valueRequired: `Valeur est requise`,
        noteRequired: 'Note est requise',
        positionNameRequired: 'Le nom du poste est requis',
        businessNameRequired: `Le nom de l'entreprise est requis`,
        candidateStatusRequired: `Le statut du candidat est requis`,
        contractStartDateRequired: 'La date de début la plus proche est requise',
        contractEndDateRequired: `La date de fin du contrat est requise`,
        contractDetailsWordingRequired: `La description du contrat est requise`,
        startDateRequired: `La date de début la plus proche est requise`,
        storeNumberRequired: 'Le numéro de succursale est requis',
        formTypeRequired: 'Form type is required',
        startNewHire: 'Nouvelle embauche',
        startWorkPermit: 'Nouvelle embauche pour candidat avec permis de travail',
        startSeasonalContractor: 'Nouvelle embauche pour candidat saisonnier',
        submit: 'Soumettre',
        workPermitEndDate: 'Fin du permis de travail',
        country: 'Pays',
        partTimeHoursPerWeek: 'Heures/semaine',
      },
      messageAfterHiringModal: {
        headerText: 'AppyHere informera le candidat de son embauche.',
        modalText:
          "L'embauchage du candidat le définira comme inactif. Le candidat ne sera plus disponible pour d'autres postes.",
        dismissText: 'Ne plus afficher ce message',
        buttonText: 'Ok',
      },
      rejectModal: {
        unhireCandidate: "Annuler l'embauche du candidat",
        reasonForChange: "Donnez une raison pour l'annulation d'embauche",
        header: 'Embaucher le candidat',
        body: 'Le candidat a-t-il été embauché ?',
        hired: 'Commencer à embaucher',
        rejected: 'Offre refusée',
        seenToCandidatePlaceholder: 'L’information inscrite ici ne sera pas montrée au candidat',
        placeholder: 'Ajoute tes commentaires',
      },
      actionsAfterUnhire: {
        removeForever: 'Enlever pour toujours',
        hireInFuture: 'Je voudrais peut-être embaucher ce candidat dans le futur',
        hireHimAgain: 'Pensez-vous rembaucher ce candidat?',
        headerText: `Annuler l'embauche d'un candidat`,
      },
    },
    soon: 'bientôt',
  },
  candidateLocked: `Message du candidat. Déverrouillez-le dans l'application mobile`,
  interviewRating: {
    title: 'Évaluation du candidat',
    subTitle: `Comment s’est passée l’entrevue avec le candidat?`,
    lowReason: 'Sélectionnez une raison de la faible évaluation:',
    commentPlaceholder: 'Laisse un commentaire',
    successful: 'Évaluation réussie',
    unsuccessful: 'Le candidat doit avoir un rendez-vous pour pouvoir envoyer une évaluation.',
    dateAndTimeOFInterview: `Date & heure de l'entrevue`,
    interviewType: `Type de l'entrevue`,
    feedback: 'Évaluation du candidat',
    feedbackDetail: `Comment s'est passée l'entrevue avec le candidat?`,
    recruitersFeedback: 'Commentaires ',
  },
  chatInput: {
    deactivatedUserLabel: `Le candidat doit avoir passé une entrevue pour être évalué.`,
  },
  headerWhenRequisitionEnabled: 'Offre faite',
  offerMadewarning: {
    title: 'Embauches et offres pour ce candidat',
    subtitle: "Ce candidat a déjà été embauché ou à reçu des offres d'emploi.",
    continueAnyWay: 'Envoyer quand même',
    position: 'Poste',
    recruiter: 'Recruteur',
    date: "Date de l'offre",
    offerStatus: "Statut de l'offre",
    currentStatus: 'Status du candidat',
    offerHistory: "Historique d'offres d'emploi et d'embauches",
    hireThroughAPI: 'Recruteur virtuel',
  },
};
