import React, { useEffect, useState, Fragment } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AvatarBadge,
  Box,
  Flex,
  Text,
  Button,
  useDisclosure,
  Tooltip,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import { BsBookmarkStar } from 'react-icons/bs';
import moment from 'moment-timezone';
import { RiCheckboxCircleFill } from 'react-icons/ri';
import _isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import { Label, ProfilePicture } from '../../common';
import colors from '../../../styles/colors';
import { useStoreState } from '../../../models/hooks';
import { RemoteConfigKey } from '../../../firebase/remoteConfig';
import { useTopCandidatesContext, SelectedTab } from './TopCandidatesContext';
import SeekerExperiencePresenter from '../../messaging/seeker/candidate/profile/seekerExperiencePresenter';
import SeekerEducationPresenter from '../../messaging/seeker/candidate/profile/seekerEducationPresenter';
import { Experience } from '../../messaging/seeker/candidate/profile/components/Experience';
import { Education } from '../../messaging/seeker/candidate/profile/components/Education';
import useEducationLevels from '../../../app/hooks/useEducationLevels';
import { CandidateAvailabilities } from '../../messaging/seeker/candidate/profile/components/CandidateAvailabilities';
import { ContactRequestModalView } from './ContactRequestModalView';
import { CandidateNightAvailabilities } from '../../messaging/seeker/candidate/profile/components/CandidateNightAvailabilities';
import { getGradientBackground } from '../../../utils/uiUtils';

export type SeekerProfilePropsType = {
  onDismissModalOpen: () => void;
  onEnagageModalOpen: () => void;
};

enum AvailabilityTypes {
  DAY = 'Day',
  NIGHT = 'Night',
}

export const SeekerProfile = ({ onDismissModalOpen, onEnagageModalOpen }: SeekerProfilePropsType): JSX.Element => {
  const [profileURL, setProfileURl] = useState<string>();
  const [availabilityType, setAvailabilityType] = React.useState<AvailabilityTypes>(AvailabilityTypes.DAY);

  const { t, i18n } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const minutesBeforeInactivity = useStoreState((state) => state.remoteConfig[RemoteConfigKey.MINUTES_BEFORE_INACTIVITY]);
  const { tab, selectedTopCandidate, positionDocument } = useTopCandidatesContext();
  const seekersProfilePictures = useStoreState((state) => state.messaging.seekersProfilePictures);
  const recentlyActive =
    moment(selectedTopCandidate?.seeker?.lastActivity).toDate() > moment().subtract(minutesBeforeInactivity, 'minute').toDate();

  const candidateExperiences = (
    selectedTopCandidate?.seeker?.experiences?.map((experience) => new SeekerExperiencePresenter(experience)) ?? []
  ).sort((x, y) => Number(y.current) - Number(x.current));

  const candidateEducations =
    selectedTopCandidate?.seeker?.educations?.map((education) => new SeekerEducationPresenter(education)) ?? [];

  const educationLevels = useEducationLevels();

  useEffect(() => {
    if (selectedTopCandidate?.seeker) {
      const url = seekersProfilePictures[selectedTopCandidate?.seeker?.id];
      setProfileURl(url);
    }
  }, [selectedTopCandidate, seekersProfilePictures]);

  return (
    <Box h="100%">
      {selectedTopCandidate?.seeker && (
        <Box position="relative" h="100%" pb="64px">
          <Box height="100%" overflowY="auto">
            <Box p="12px" borderBottom="1px solid #E5E7EB">
              <Flex alignItems="center">
                <ProfilePicture
                  ml="10px"
                  src={profileURL}
                  style={{ backgroundColor: colors.orange[500], color: 'white' }}
                  name={`${selectedTopCandidate?.seeker.firstName} ${selectedTopCandidate?.seeker.lastName}`}
                  bg="transparent"
                >
                  {recentlyActive && <AvatarBadge width="14px" height="14px" bg={colors.green['200']} />}
                </ProfilePicture>
                <Box ml="10px">
                  <Flex alignItems="center">
                    <Text fontWeight={700} fontSize="16px" mr="4px">
                      {`${selectedTopCandidate?.seeker.firstName} ${selectedTopCandidate?.seeker.lastName}`}
                    </Text>
                    <BsBookmarkStar />
                  </Flex>
                </Box>
              </Flex>
            </Box>
            <Box p="12px">
              {selectedTopCandidate?.seeker.flexibleTransportation && (
                <Fragment>
                  <Text fontSize="14px" fontWeight={700}>
                    {t('common:profile.appyPros.title')}
                  </Text>
                  <Flex alignItems="center">
                    <RiCheckboxCircleFill color={colors.green[500]} size={24} />
                    <Text ml="12px" fontSize="14px" fontWeight={400} color={colors.slate}>
                      {t('common:profile.appyPros.flexibleTransportation')}
                    </Text>
                  </Flex>
                </Fragment>
              )}
            </Box>

            {selectedTopCandidate?.seeker.noticePeriod &&
              selectedTopCandidate?.seeker.noticePeriod.value &&
              selectedTopCandidate?.seeker.noticePeriod.value !== '' && (
                <Box p="0 12px 12px 12px" borderBottom="1px solid #E5E7EB">
                  <Text fontWeight="semibold" fontSize=".95rem">
                    {t('common:noticePeriod')}
                  </Text>
                  <Text color="gray.500" fontSize="14px">
                    {selectedTopCandidate?.seeker.noticePeriod.value === '0'
                      ? t('common:immediately')
                      : `${selectedTopCandidate?.seeker.noticePeriod.value} ${selectedTopCandidate?.seeker.noticePeriod.unit}`}
                  </Text>
                </Box>
              )}
            <Box p="0 0 12px 12px">
              <Accordion defaultIndex={[0]} allowMultiple>
                <AccordionItem border="none" p="0px" pr="12px" borderBottom="1px solid #E5E7EB">
                  <h2>
                    <AccordionButton p="12px 0px" _focus={{ outline: 'none' }}>
                      <Box as="span" flex="1" textAlign="left" p="0px" fontSize="14px" fontWeight={700}>
                        {t('common:profile.experience.title')}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb="12px" px={0}>
                    {candidateExperiences.length > 0 &&
                      candidateExperiences.map((experience, index) => (
                        <Experience
                          key={experience.id}
                          experience={experience}
                          totalItems={candidateExperiences.length}
                          dataIndex={index}
                        />
                      ))}
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem border="none" p="0px" pr="12px" borderBottom="1px solid #E5E7EB">
                  <h2>
                    <AccordionButton p="12px 0px" _focus={{ outline: 'none' }}>
                      <Box as="span" flex="1" textAlign="left" p="0px" fontSize="14px" fontWeight={700}>
                        {t('common:profile.education.title')}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb="12px" px={0}>
                    {candidateEducations.length > 0 &&
                      candidateEducations.map((education, index) => (
                        <Education
                          key={education.id}
                          dataIndex={index}
                          education={education}
                          totalItems={candidateEducations.length}
                          educationLevels={educationLevels}
                        />
                      ))}
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem border="none" p="0px" pr="12px" borderBottom="1px solid #E5E7EB">
                  <h2>
                    <AccordionButton p="12px 0px" _focus={{ outline: 'none' }}>
                      <Box flex="1" textAlign="left">
                        <Text fontWeight="semibold" pb={1} fontSize="14px">
                          {t('profile.availability.title')}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  {(selectedTopCandidate?.seeker?.newAvailabilities || selectedTopCandidate?.seeker?.nightAvailabilities) &&
                  (!_isEmpty(selectedTopCandidate?.seeker?.newAvailabilities) ||
                    !_isEmpty(selectedTopCandidate?.seeker?.nightAvailabilities)) ? (
                    // eslint-disable-next-line react/jsx-indent
                    <AccordionPanel pb={0} px={0} mb={3} borderTopWidth="1px" borderTopColor="#ededed" borderTopStyle="solid">
                      {selectedTopCandidate?.seeker.availabilityUpdatedAt && (
                        <Box fontSize="sm" color="gray.500" mb={2}>
                          {`${t('profile.updatedAt')} ${moment(selectedTopCandidate?.seeker.availabilityUpdatedAt)
                            .locale(i18n.language)
                            .format('lll')}`}
                        </Box>
                      )}
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        border="1px solid #E5E7EB"
                        borderRadius="4px"
                        mb={2}
                        p={1}
                        position="relative"
                        width="100%"
                        height="42px"
                        background={getGradientBackground(availabilityType)}
                        overflow="hidden"
                      >
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          fontWeight="700"
                          cursor="pointer"
                          flex="1"
                          mr="50px"
                          mt="8px"
                          onClick={() => setAvailabilityType(AvailabilityTypes.DAY)}
                          zIndex={1}
                          color={availabilityType === AvailabilityTypes.DAY ? 'blue.500' : 'gray.800'}
                        >
                          <Text fontSize="14px" fontWeight={700}>
                            {t('availability.day')}
                          </Text>
                        </Flex>
                        <Flex
                          alignItems="center"
                          justifyContent="center"
                          fontWeight="700"
                          cursor="pointer"
                          flex="1"
                          ml="50px"
                          mb="8px"
                          onClick={() => setAvailabilityType(AvailabilityTypes.NIGHT)}
                          zIndex={1}
                          color={availabilityType === AvailabilityTypes.NIGHT ? 'blue.500' : 'gray.800'}
                        >
                          <Text fontSize="14px" fontWeight={700}>
                            {t('availability.night')}
                          </Text>
                        </Flex>
                      </Flex>

                      {/* eslint-disable no-nested-ternary */}
                      {availabilityType === AvailabilityTypes.DAY && selectedTopCandidate?.seeker?.newAvailabilities ? (
                        <CandidateAvailabilities candidateAvailabilities={selectedTopCandidate?.seeker.newAvailabilities} />
                      ) : availabilityType === AvailabilityTypes.NIGHT && selectedTopCandidate?.seeker?.nightAvailabilities ? (
                        <CandidateNightAvailabilities nightAvailabilities={selectedTopCandidate?.seeker?.nightAvailabilities} />
                      ) : (
                        <Label
                          p={4}
                          size="sm"
                          color={colors.gray[500]}
                          colorDarkMode={colors.gray['600']}
                          border="1px solid #eceff1"
                        >
                          ---
                        </Label>
                      )}
                    </AccordionPanel>
                  ) : (
                    <AccordionPanel pb={0} px={0} mb={3} borderTopWidth="1px" borderTopColor="#ededed" borderTopStyle="solid">
                      <Label
                        p={4}
                        size="sm"
                        color={colors.gray[500]}
                        colorDarkMode={colors.gray['600']}
                        border="1px solid #eceff1"
                      >
                        ---
                      </Label>
                    </AccordionPanel>
                  )}
                </AccordionItem>
              </Accordion>
            </Box>
          </Box>
          <Box py={3} px={3} w="100%" bg="#fff" position="absolute" left="0" bottom="0" borderTop="1px solid #E5E7EB">
            <Flex justifyContent="space-between">
              <Fragment>
                <Box>
                  <Button variant="outline" colorScheme="red" size="sm" fontSize="15px" onClick={() => onDismissModalOpen()}>
                    {t('businessManagement:topCandidate.dismissCandidate')}
                  </Button>
                </Box>

                <Box>
                  {tab === SelectedTab.BEST_MATCH && (
                    <Button variant="solid" colorScheme="blue" size="sm" fontSize="15px" onClick={() => onOpen()}>
                      {t('businessManagement:topCandidate.sendContactRequest')}
                    </Button>
                  )}
                  {tab === SelectedTab.BOOKMARKED && (
                    <Tooltip
                      label={
                        selectedTopCandidate.status !== 'CONTACT_REQUEST_ACCEPTED'
                          ? t('businessManagement:topCandidate.engageDisableMsg')
                          : ''
                      }
                      shouldWrapChildren
                      aria-label="A tooltip"
                    >
                      <Button
                        isDisabled={selectedTopCandidate.status !== 'CONTACT_REQUEST_ACCEPTED'}
                        variant="solid"
                        colorScheme="blue"
                        size="sm"
                        fontSize="15px"
                        onClick={() => onEnagageModalOpen()}
                      >
                        {t('businessManagement:topCandidate.engageBtnLabel')}
                      </Button>
                    </Tooltip>
                  )}
                </Box>
                {isOpen && selectedTopCandidate && (
                  <ContactRequestModalView isOpen={isOpen} onClose={onClose} selectedTopCandidate={selectedTopCandidate} />
                )}
              </Fragment>

              {tab === SelectedTab.ACTIVE && (
                <Fragment>
                  <Box />
                  <Box>
                    <Link to={`/candidate/${positionDocument?.id}_${selectedTopCandidate.seeker.id}/messaging`}>
                      <Button variant="solid" colorScheme="blue" size="sm" fontSize="15px">
                        {t('businessManagement:topCandidate.communicationCandidate')}
                      </Button>
                    </Link>
                  </Box>
                </Fragment>
              )}
            </Flex>
          </Box>
        </Box>
      )}
    </Box>
  );
};
