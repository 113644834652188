import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  Box,
  Flex,
  Text,
  Avatar,
} from '@chakra-ui/core';
import { useTranslation } from 'react-i18next';
import _isNil from 'lodash/isNil';
import _isNaN from 'lodash/isNaN';
import i18next from 'i18next';
import { css } from '@emotion/core';
import moment from 'moment-timezone';
import { TodosType } from '../../firebase/firestore/documents/lookups';
import { getTranslatedValue } from '../../utils/localizedString';
import { useStoreActions, useStoreState } from '../../models/hooks';
import colors from '../../styles/colors';

export const modalCss = css`
  .modal-description {
    a {
      color: ${colors.blue['500']};
    }
  }
`;

export enum RecruiterAction {
  SEND_REMINDER,
  DISMISSED,
  INFORMATION,
  MESSAGE,
}

export type RecruiterActionFormModalProps = {
  selectedToDoItem: {
    id: string;
    task: string;
    candidateName: string;
    candidateId: string;
    helpRequestedMessages?: {
      userType: string;
      message: string;
      timestamp: Date;
      name: string;
    }[];
    latestHelpRequestFor?: string;
    seekerHireDetailId?: string;
    hireDetailsRequestedAt?: string;
  };
  candidateTodos: TodosType;
  onClose: () => void;
  isOpen: boolean;
};
export const RecruiterActionFormModal = ({
  selectedToDoItem,
  candidateTodos,
  isOpen,
  onClose,
}: RecruiterActionFormModalProps): JSX.Element => {
  const { t } = useTranslation(['toDoList', 'hireDetail']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { updateRecruiterActionStatus } = useStoreActions((actions) => actions.toDoList);
  const { sendMessage, dismissCandidate } = useStoreActions((actions) => actions.messaging);
  const userId = useStoreState((states) => states.app.user?.id);

  const getMessageOfSelectedTodoItem = (title: string) => {
    const hasMessage = candidateTodos[title as keyof TodosType];
    const newLine = '\r\n';
    if (hasMessage.modalMessage) {
      if (hasMessage.modalMessage.en === '') {
        return '';
      }
      return `${t('hi')} ${selectedToDoItem.candidateName.split(' ')[0]}, ${newLine}${getTranslatedValue(
        hasMessage.modalMessage,
        i18next.language,
      )}`;
    }
    return null;
  };

  const [messageFormRecruiterAction, setMessageForRecruiterAction] = useState<string | null>(
    getMessageOfSelectedTodoItem(selectedToDoItem.task),
  );

  const getToDoDetail = (status: string) => {
    switch (status) {
      case 'offerMade12HoursAgo':
        return {
          title: t('toDoList:offerTitle'),
          description: t('toDoList:offerMade12HoursAgoDesp'),
          action: RecruiterAction.SEND_REMINDER,
        };
      case 'offerMade24HoursAgo':
        return {
          title: t('toDoList:offerTitle'),
          description: t('toDoList:offerMade24HoursAgoDesp'),
          action: RecruiterAction.SEND_REMINDER,
        };
      case 'offerMade36HoursAgo':
        return {
          title: t('toDoList:offerTitle'),
          description: t('toDoList:offerMade36HoursAgoDesp'),
          action: RecruiterAction.SEND_REMINDER,
        };
      case 'offerMade48HoursAgo':
        return {
          title: t('toDoList:dismissedCandidateTitle'),
          description: t('toDoList:offerMade48HoursAgoDesp'),
          action: RecruiterAction.DISMISSED,
        };
      case 'hireFormRequested12HoursAgo':
        return {
          title: t('toDoList:hireFormTitle'),
          description: t('toDoList:hireFormRequested12HoursAgoDesp'),
          action: RecruiterAction.SEND_REMINDER,
        };
      case 'hireFormRequested24HoursAgo':
        return {
          title: t('toDoList:hireFormTitle'),
          description: t('toDoList:hireFormRequested24HoursAgoDesp'),
          action: RecruiterAction.SEND_REMINDER,
        };
      case 'hireFormRequested48HoursAgo':
        return {
          title: t('toDoList:hireFormTitle'),
          description: t('toDoList:hireFormRequested48HoursAgoDesp'),
          action: RecruiterAction.SEND_REMINDER,
        };
      case 'hireFormRequested60HoursAgo':
        return {
          title: t('toDoList:hireFormTitle'),
          description: t('toDoList:hireFormRequested60HoursAgoDesp'),
          action: RecruiterAction.SEND_REMINDER,
        };
      case 'hireFormRequested72HoursAgo':
        return {
          title: t('toDoList:dismissedCandidateTitle'),
          description: t('toDoList:hireFormRequested72HoursAgoDesp'),
          action: RecruiterAction.DISMISSED,
        };

      case 'hireFormResubmission12HoursAgo':
        return {
          title: t('toDoList:hireFormResubmissionTitle'),
          description: t('toDoList:hireFormRequested12HoursAgoDesp'),
          action: RecruiterAction.SEND_REMINDER,
        };
      case 'hireFormResubmission24HoursAgo':
        return {
          title: t('toDoList:hireFormResubmissionTitle'),
          description: t('toDoList:hireFormRequested24HoursAgoDesp'),
          action: RecruiterAction.SEND_REMINDER,
        };
      case 'hireFormResubmission48HoursAgo':
        return {
          title: t('toDoList:hireFormResubmissionTitle'),
          description: t('toDoList:hireFormRequested48HoursAgoDesp'),
          action: RecruiterAction.SEND_REMINDER,
        };
      case 'hireFormResubmission60HoursAgo':
        return {
          title: t('toDoList:hireFormResubmissionTitle'),
          description: t('toDoList:hireFormRequested60HoursAgoDesp'),
          action: RecruiterAction.SEND_REMINDER,
        };
      case 'hireFormResubmission72HoursAgo':
        return {
          title: t('toDoList:dismissedCandidateTitle'),
          description: t('toDoList:hireFormRequested72HoursAgoDesp'),
          action: RecruiterAction.DISMISSED,
        };
      case 'hireFormHelpRequested':
        return {
          title: t('toDoList:hireForHelpRequestTitle'),
          description: t('toDoList:hireFormHelpRequestDesp'),
          action: RecruiterAction.MESSAGE,
        };

      case 'bgCheckRequested12HoursAgo':
        return {
          title: t('toDoList:backgroundCheckTitle'),
          description: t('toDoList:bgCheckRequested12HoursAgoDesp'),
          action: RecruiterAction.SEND_REMINDER,
        };
      case 'bgCheckRequested24HoursAgo':
        return {
          title: t('toDoList:backgroundCheckTitle'),
          description: t('toDoList:bgCheckRequested24HoursAgoDesp'),
          action: RecruiterAction.SEND_REMINDER,
        };
      case 'bgCheckRequested36HoursAgo':
        return {
          title: t('toDoList:backgroundCheckTitle'),
          description: t('toDoList:bgCheckRequested36HoursAgoDesp'),
          action: RecruiterAction.SEND_REMINDER,
        };
      case 'bgCheckRequested48HoursAgo':
        return {
          title: t('toDoList:backgroundCheckTitle'),
          description: t('toDoList:bgCheckRequested48HoursAgoDesp'),
          action: RecruiterAction.SEND_REMINDER,
        };
      case 'bgCheckRequested72HoursAgo':
        return {
          title: t('toDoList:dismissedCandidateTitle'),
          description: t('toDoList:bgCheckRequested72HoursAgoDesp'),
          action: RecruiterAction.DISMISSED,
        };

      case 'bgCheckNotProcessedByCertn24HoursAgoCandidates':
        return {
          title: t('toDoList:contactCertn'),
          description: t('toDoList:bgCheckNotProcessedByCertn24HoursAgoCandidatesDesp'),
          action: RecruiterAction.INFORMATION,
          email: 'support@certn.co',
        };
      case 'bgCheckFlaggedByCertnCandidates':
        return {
          title: t('toDoList:backgroundCheckTitle'),
          description: t('toDoList:bgCheckFlaggedByCertnCandidatesDesp'),
          action: RecruiterAction.INFORMATION,
        };

      case 'hireFormCompleted24HoursAgo':
      default:
        return {
          title: t('toDoList:contactDollarama'),
          description: t('toDoList:hireFormCompleted24HoursAgoDesp'),
          action: RecruiterAction.INFORMATION,
          email: 'payroll@dollarama.com',
        };
    }
  };

  const handleRecruiterAction = async () => {
    setIsLoading(true);
    const seekerIdAndPositionId = selectedToDoItem.candidateId.split('_');
    if (getToDoDetail(selectedToDoItem.task).action !== RecruiterAction.INFORMATION && !_isNil(messageFormRecruiterAction)) {
      await sendMessage({
        message: messageFormRecruiterAction,
        positionId: seekerIdAndPositionId[0],
        seekerId: seekerIdAndPositionId[1],
      });
    }

    // istanbul ignore else
    if (userId) {
      if (getToDoDetail(selectedToDoItem.task).action === RecruiterAction.DISMISSED) {
        await dismissCandidate({
          positionId: seekerIdAndPositionId[0],
          seekerId: seekerIdAndPositionId[1],
          recruiterId: userId,
        });
      }

      await updateRecruiterActionStatus({
        userId,
        docId: selectedToDoItem.id,
        status: 'COMPLETED',
        category: selectedToDoItem.task,
        subCategory: null,
        candidateId: selectedToDoItem.candidateId,
      });
    }
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{getToDoDetail(selectedToDoItem.task).title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box css={modalCss}>
            {!_isNaN(getToDoDetail(selectedToDoItem.task).description) && getToDoDetail(selectedToDoItem.task).description && (
              <Box
                className="modal-description"
                mb={5}
                fontSize="15px"
                fontWeight="600"
                lineHeight="21px"
                dangerouslySetInnerHTML={{ __html: getToDoDetail(selectedToDoItem.task).description }}
              />
            )}
            {selectedToDoItem.task === 'hireFormHelpRequested' && (
              <Text mb={4}>
                {selectedToDoItem.latestHelpRequestFor && (
                  <Box mb={2} fontSize="15px" fontWeight="600">
                    {t('toDoList:helpRequestFor', { feildName: t(`hireDetail:${selectedToDoItem?.latestHelpRequestFor}`) })}
                  </Box>
                )}
                {selectedToDoItem.helpRequestedMessages && (
                  <Box border="1px solid #e2e8f0" p="9px" borderRadius="5px">
                    {selectedToDoItem.helpRequestedMessages.map((msg) => (
                      <Box key={msg.message} alignItems="center" mb={2}>
                        <Flex alignItems="center" mb={3}>
                          <Box mr={2}>
                            <Avatar bg="tomato" size="sm" name={msg.name} />
                          </Box>
                          <Box>
                            <Text fontSize="xs" color="#8e8e8e">
                              {moment(msg.timestamp).format('LLL')}
                            </Text>
                            <Text fontSize="sm" fontWeight="600">
                              {msg.name}
                            </Text>
                          </Box>
                        </Flex>
                        <Box w="100%" background="#f6f6f6" borderRadius="8px" p="16px" lineHeight={1.1} fontSize="15px">
                          {msg.message}
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
              </Text>
            )}
            {!_isNil(messageFormRecruiterAction) && (
              <Textarea
                borderRadius="6px"
                borderColor={colors.lavenderMist}
                lineHeight="20px"
                placeholder={t('toDoList:enterYourMessage')}
                size="sm"
                value={messageFormRecruiterAction}
                onChange={(e) => setMessageForRecruiterAction(e.target.value.trimStart())}
                rows={5}
                data-testid="recruiter-message"
              />
            )}
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onClose}>
            {t('toDoList:cancel')}
          </Button>

          {getToDoDetail(selectedToDoItem.task).action === RecruiterAction.INFORMATION ? (
            <a
              href={`mailto:${getToDoDetail(selectedToDoItem.task).email}${
                selectedToDoItem.seekerHireDetailId &&
                `?&subject=${t('toDoList:mailtoHireForm')}${selectedToDoItem.seekerHireDetailId}`
              }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button colorScheme="blue" data-testid="sendEmailBtn" onClick={() => handleRecruiterAction()}>
                {t('toDoList:sendEmail')}
              </Button>
            </a>
          ) : (
            <Button
              isLoading={isLoading}
              data-testid="sendReminderBtn"
              loadingText={t('toDoList:sending')}
              colorScheme="blue"
              disabled={messageFormRecruiterAction === ''}
              onClick={() => handleRecruiterAction()}
            >
              {getToDoDetail(selectedToDoItem.task).action === RecruiterAction.DISMISSED
                ? t('toDoList:sendAndDismissed')
                : t('toDoList:send')}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
