export default {
  jobSearchPlaceholder: 'Recherchez par titre de position',
  createDate: 'Date de création',
  modifyDate: 'Date de modification',
  allPositions: 'Tous les postes',
  openPositions: 'Postes ouverts',
  openedPositions: 'Postes ouverts',
  closedPositions: 'Postes fermés',
  refresh: 'Rafraîchir',
  archivedPositions: 'Postes archivés',
  followedPositions: 'Postes suivis',
  archivedPosition: 'Positions archivées',
  position: 'poste',
  positions: 'postes',
  counting: 'Counting',
  filterByRequisitions: 'Cacher les postes sans réquisitions ouvertes',
  time: 'Type de poste',
  partTime: 'Temps partiel',
  fullTime: 'Temps plein',
  applicants: 'Candidats actifs',
  noOfHires: "Nombre d'embauches",
  open: 'Ouvrir',
  close: 'Fermer',
  archive: 'Archiver',
  unArchive: 'Désarchivé',
  follow: 'Suivre',
  unFollow: 'Arrêter de suivre',
  copy: 'Copier',
  edit: 'Modifier',
  recruiterFollowing: 'Recruteurs suivant ce poste',
  share: 'Partager',
  copiedMessage: 'Vous avez copié le lien pour ce poste.',
  noJobs: "Désolé, nous n'avons trouvé aucun résultat correspondant à cette recherche",
  loadMore: 'Montrer plus',
  searchPlaceholder: 'Rechercher par nom de poste',
  unfollowModal: {
    header: 'Dernier recruteur',
    body: 'Vous êtes la dernière personne à suivre ce poste. Si vous arrêtez de le suivre, il sera fermé',
  },
  unfollowWarningModal: {
    header: 'Êtes-vous certain de vouloir arrêter de suivre ce poste?',
    body:
      'Assurez-vous de ne pas avoir des entrevues de cédulées pour ce poste avant d’arrêter de le suivre. Il sera beaucoup plus difficile de surveiller ces candidats lorsque vous ne suivez plus le poste.',
  },
  positionImpactedModal: {
    emptyAutomatedPositions: 'Aucun poste affecté.',
  },
  openPositionModal: {
    title: 'Ouvrir ce poste',
    subtitle: 'Ce poste est configuré pour être automatisé avec les paramètres suivants:',
    disabled: {
      title: 'Trouvez des candidats',
      description: 'Je vais vous trouver les meilleurs candidats',
    },
    screening: {
      title: 'Présélectionnez les candidats',
      description: {
        zero: 'Je vais présélectionner {{count}} candidat',
        one: 'Je vais présélectionner {{count}} candidat',
        other: 'Je vais présélectionner {{count}} candidats',
      },
    },
    scheduling: {
      title: 'Occupez-vous de tout',
      description: 'Je vais planifier des entrevues avec les candidats',
    },
    noAutomation: {
      description: 'Êtes-vous sûr de vouloir ouvrir ce poste?',
    },
    openPositionButton: 'Confirmer',
    cancelModalButton: 'Annuler',
    requisition: {
      title: 'Réquisition',
      subtitle: 'Ce poste est contrôlé par le système de gestion des réquisitions. Voulez-vous toujours ouvrir ce poste?',
    },
  },
  closePositionModal: {
    title: 'Fermer ce poste',
    subtitle: {
      zero: 'Êtes-vous sûr de vouloir fermer ce poste?',
      one: '{{count}} autre membre de votre équipe suit ce poste.\nÊtes-vous sûr de vouloir fermer ce poste?',
      other: '{{count}} autres membres de votre équipe suivent ce poste.\nÊtes-vous sûr de vouloir fermer ce poste?',
    },
    closePositionButton: {
      title: 'Confirmer',
    },
    requisition: {
      title: 'Réquisition',
      subtitle:
        'Ce poste sera automatiquement ouvert par le système de gestion des réquisitions. Voulez-vous toujours ouvrir ce poste?',
    },
  },
  archivePositionModal: {
    title: 'Archivé un poste',
    bodyMessage: 'Êtes-vous certain de vouloir archiver ce poste?',
    confirmText: 'Oui',
    cancelText: 'Annuler',
  },
  unarchivePositionModal: {
    title: 'Désarchivé un poste',
    bodyMessage: 'Êtes-vous certain de vouloir désarchiver ce poste?',
    confirmText: 'Oui',
    cancelText: 'Annuler',
  },
  info: {
    archived: 'Le poste que vous avez archivé est ici.',
    open: 'Le poste que vous avez ouvert est ici.',
    unArchive: 'Le poste que vous avez désarchivé est ici.',
    close: 'Le poste que vous avez fermé est ici.',
    copy: 'La position que vous venez de copier est ici',
  },
  createNewPosition: 'Créer un nouveau poste',
  editPosition: 'Modifier le poste',
  viewPositionList: 'Voir la liste des postes',
  newJobFor: 'Situé chez',
  updateJobPosition: 'Situé chez',
  automationTypes: {
    selectAutomationLevel: `Choisir le niveau d'automatisation`,
    none: `Pas d'automatisation`,
    screen: 'Présélectionner',
    screenAndScheduleInterviews: `Présélectionner & inviter à l'entrevue`,
    screenAndSendOffers: 'Présélectionner & envoyer les offres',
  },
  screenAndSendOffersModal: {
    warningText: `Pour que le recruteur virtuel fonctionne correctement, vous devez vous assurez que toutes les configurations nécessaires sont réglées pour ce poste. Si le poste n'a pas été configuré encore, consultez un administrateur de votre équipe pour pouvoir configurer le poste correctement.`,
    submitText: `Régler le niveau d'automatisation`,
    cancelText: 'Annuler',
  },
  screeningInputModal: {
    submitText: 'Confirmer',
    cancelText: 'Annuler',
  },
  positionAutomationSelect: {
    errorInUpdating: 'Une erreur est survenue pendant la mise à jour du poste.',
    errorUpdatingDescription: `Le niveau d'automatisation ne pouvait pas être changé. Essayez de nouveau!`,
    successInUpdating: `Le niveau d'automatisation {{automationLevel}} a été configuré pour ce poste avec succès.`,
  },
  automation: {
    title: 'Niveau d’automatisation',
    findMeCandidate: {
      toolTipMessage:
        'AppyHere va trouver les candidats idéales pour vous. Tout ce que vous devez faire est choisir ceux que vous voulez passer en entrevue!',
      label: 'AppyHere va trouver les candidats idéales pour vous',
    },
    screening: {
      toolTipMessage:
        'AppyHere va présélectionner {{count}} candidats pour vous. Tout ce que vous devez faire est de choisir ceux que vous voulez passer en entrevue!',
      label: 'AppyHere va présélectionner {{count}} candidats.',
    },
    scheduling: {
      toolTipMessage:
        "AppyHere va présélectionner et envoyer des demandes d'entrevues aux candidats pour vous. Tout ce que vous devez faire est de régler vos disponibilités et laisser les entrevues se planifier eux-mêmes!",
      label: "AppyHere va présélectionner et envoyer des demandes d'entrevues aux candidats",
    },
    schedulingVrEnabled: {
      toolTipMessage: "AppyHere le fera tout pour vous, même les offres d'emploi!",
      label: "AppyHere va présélectionner les candidats et leur envoyer des offres d'emploi.",
    },
  },
  days: {
    mon: 'Lundi',
    tue: 'Mardi',
    wed: 'Mercredi',
    thu: 'Jeudi',
    fri: 'Vendredi',
    sat: 'Samedi',
    sun: 'Dimanche',
  },
  availabilityHint:
    'Déterminez les jours et heures pour lesquels les candidats devraient être disponibles. Sélectionnez les jours, et ensuite utilisez le curseur pour régler les quarts de travail.',
  userRestrictedByAuthorizationMessage:
    'Pour faire cette action, vous devez contacter account-validation@appyhere.com pour vérifier votre compte.',
};
