import { EducationStatus } from '../../firebase/firestore/documents/seeker';

export default {
  send: 'Send',
  email: 'Email',
  search: {
    label: 'Search',
  },
  sorting: {
    label: 'Sort by:',
    alphabetical: 'A-Z',
    chronological: 'Date',
  },
  grouping: {
    label: 'Group by:',
    none: 'None',
    position: 'Position',
    businessLocation: 'Location',
  },
  tabs: {
    profile: 'Profile',
    audioRecording: 'Language Evaluation',
    messaging: 'Messaging',
    questions: 'Questions',
    notes: 'Notes',
    history: 'History',
    info: 'Information',
    PASS: 'Passed',
    FAIL: 'Failed',
    NEEDS_REVIEW: 'Needs Interview',
  },
  account: {
    switchLang: 'Français',
    logout: 'Logout',
    switchColorMode: {
      dark: 'Switch to light mode',
      light: 'Switch to dark mode',
    },
    administration: 'Administration',
    recruiter: 'Recruiter',
    myProfile: 'My Profile',
  },
  logoutConfirmation: {
    message: 'Are you sure you want to log out of AppyHere?',
    logout: 'Logout',
    cancel: 'Cancel',
  },
  profile: {
    isAStudent: 'Student',
    isStudentInfo: 'This candidate is a student.',
    adult: 'Adult',
    adultInfo: 'This candidate is more than 18 years of age.',
    joinDate: 'Joined APPYHERE: {{date}}',
    appyPros: {
      title: 'Candidate Insights',
      flexibleTransportation: 'Has access to flexible transportation',
      positivePoint: 'Positive point',
    },
    appyNeg: {
      highSalaryDemand: 'High Salary Expectations',
      negativePoint: 'Negative point',
    },
    matchingRequisition: {
      title: 'Ideal Requisitions',
      availabilityFitBand: 'Availability Fit:',
      goodFit: 'Good',
      mediumFit: 'Medium',
      poorFit: 'Poor',
      badFit: 'Poor',
      noFit: 'No Match Calculated',
      not_fitFit: 'No Match Calculated',
      offerMade: 'Make Offer',
    },
    experience: {
      title: 'Experience',
      current: 'Current',
      none: 'No experience',
      fetching: 'Fetching Experience...',
    },
    resume: 'Resume',
    noResumeFound: 'This candidate has not yet uploaded a resume.',
    lastUpdatedResume: 'Uploaded at:',
    noResumePreviewAvailable: 'No Preview Available',
    education: {
      title: 'Education',
      none: 'No education',
      fetching: 'Fetching Education...',
      [EducationStatus.completed]: 'Completed',
      [EducationStatus.ongoing]: 'Ongoing',
      [EducationStatus.dropout]: 'Not Completed',
    },
    availability: {
      title: 'Availability',
    },
    appliedPositions: 'Positions Candidate Has Applied To',
    openRequisitions: 'Open Requisitions',
    noRequisition: 'No Requisition',
    updatedAt: 'Updated at:',
  },
  allQuestions: {
    title: 'All Questions',
  },
  requisitions: 'Requisitions',
  questions: {
    title: 'Specific Questions',
    answerPositive: 'Yes',
    answerNegative: 'No',
    positiveCount: '{{count}} out of {{total}} question answered correctly',
    positiveCount_plural: '{{count}} out of {{total}} questions answered correctly',
    emptyQuestions: 'There is no question for {{positionName}}. You can go to the AppyHere Employer mobile app to add some.',
    noAnswers: 'This position has no questions of this type.',
    preferredAnswer: 'Preferred Answer',
    knockout: 'Knockout',
    pass: 'PASS',
    fail: 'FAIL',
  },
  freeFormQuestions: {
    title: 'Free Form Questions',
    positiveCount: '{{count}} out of {{total}} question answered',
    unanswered: 'This question is unanswered.',
  },
  time: {
    relative: {
      lessThan: 'Less than 6 months',
      multipleMonths: '{{value}} months',
      oneYear: '1 year',
      multipleYears: '{{value}} years',
      moreThan: 'More than 10 years',
    },
  },
  appointmentTypes: {
    inPerson: 'in person',
    phone: 'phone',
    video: 'video',
    inPhone: 'Phone',
  },
  noMessage: 'No message, start the conversation!',
  noMessageUnfollowed: 'This candidate has no message history.',
  months: {
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
  },
  monthsSmall: {
    jan: 'Jan',
    feb: 'Feb',
    mar: 'Mar',
    apr: 'Apr',
    may: 'May',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Aug',
    sep: 'Sep',
    oct: 'Oct',
    nov: 'Nov',
    dec: 'Dec',
  },
  informationTab: {
    toastMessage: 'URL Copied',
    headerText: 'WebApply Job Listing URL',
  },
  tooltip: {
    followedPosition: 'Followed Position',
    ghostedCandidatesList: 'Show Other Candidate Lists',
    favoriteCandidates: 'My Favorites',
    archivedCandidates: 'Archived Candidates List',
  },
  candidates: {
    favoriteCandidatesList: 'Favorite Candidates List',
    candidatesList: 'Candidates List',
    unresponsiveList: 'Unresponsive List',
    archivedList: 'Archived List',
    ghostingList: 'Unresponsive',
    archiveList: 'Archived',
    dismissedList: `Dismissed`,
    agedList: `Candidates Older Than {{config}} Days`,
    recentList: `Recent List`,
  },
  button: {
    cancel: 'Cancel',
    okay: 'Ok',
    create: 'Create',
    add: 'Add',
    later: 'Later',
  },
  searchAndSort: {
    advanceSearch: 'Advanced Search',
    candidateEngagement: `Candidate Engagement Stage`,
    backgroundCheck: `Background Check Status`,
    ratings: `Rating`,
    experience: `Minimum Experience`,
    salary: `Salary`,
    expectedQuestions: `Position Questions`,
    availability: 'Availability',
    clearAll: `Clear all filters`,
    saveButton: 'Save',
    filter: 'Filter',
    hireStatus: 'Hire Status',
    modernHireStatus: 'Competency Evaluation',
    availabilityFit: 'Schedule Matching',
    priority: 'Referred Candidates',
    kiosk: 'Kiosk Candidates',
    actionRequiredCandidates: 'Needs Recruiter Intervention',
  },
  favoriteCandidate: {
    favoriteCandidate: `Favorite Candidates`,
  },
  cadidateEngagementView: {
    engaged: `Engaged`,
    appointmentRequested: `Interview Requested`,
    interviewCompleted: `Interview Completed`,
    OfferMade: `Offer Made`,
    hired: `Hired`,
    dismissed: `Dismissed`,
    offerRejected: 'Offer Rejected',
    offerAccepted: 'Offer Accepted',
    appoinmentAccepted: 'Interview Accepted',
    appoinmentDeclined: 'Interview Declined',
    appoinmentCancled: 'Interview Canceled',
    noAppointmentRequest: 'Pre-Screened',
    interestRevoked: 'Interest Revoked',
    contactRequestDeclined: 'Contact Request Declined',
  },
  backgroundCheck: {
    done: `Completed`,
    partiallyDone: `Partially Completed`,
    notDone: `Not Completed`,
    Sent: `Sent`,
    IN_PROGRESS: `In Progress`,
    DISPUTE: `Dispute`,
  },
  availability: {
    day: 'Day',
    night: 'Night',
    evening: 'Evening',
  },
  salary: {
    hourly: `Hourly`,
    annual: `Annual`,
    weekly: `Weekly`,
    monthly: `Monthly`,
    unit: `Unit`,
    min: `Min`,
    max: `Max`,
  },
  experience: {
    yr: `year`,
    yrs: `years`,
  },
  expectedQuestions: {
    typeYourQuestion: `Select your question`,
    expectedQuestion: `Questions`,
    expectedAnswer: `Expected answer`,
    yes: `Yes`,
    no: `No`,
  },
  unresponsivePeriod: {
    day: `day`,
    days: `days`,
    agedPeriod: `Delay before a candidate is considered unresponsive`,
    agedPeriodInfo: `A candidate is considered unresponsive if they have not responded to an interview request in the number of days set with this parameter. Once this delay is passed, the candidate will be moved to the Unresponsive list.`,
    agedToDismissedPeriod: `Delay before an unresponsive candidate is dismissed`,
    agedToDismissedPeriodInfo: `This slider sets the delay before an unresponsive candidate is dismissed by the system.`,
    configUpdateFailed: `Failed to update configuration.`,
    configUpdateScuccess: `Configuration updated successfully.`,
  },
  interviewFeedBack: ' Candidate Assessment',
  earliestAvailabilityDate: 'Earliest Available Date',
  noticePeriod: 'Notice Period',
  expectedWork: 'Available Work Hours',
  immediately: 'Immediately',
  videoInterviewReminder: {
    candidateInfo: 'Interview Information',
    name: 'Candidate Name',
    position: 'Position',
    location: 'Location',
    email: 'Email',
    close: 'Close',
    joinInterview: 'Join Interview',
  },
  candidateDetailsDrawer: {
    details: 'Details',
    positionDetails: 'Position Information',
    candidateDetail: 'Candidate Information',
    candidateName: 'Candidate Name',
    email: 'Email',
    phone: 'Phone',
  },
  hrsWeek: 'hrs/Week',
  days: {
    mon: 'Mon',
    tue: 'Tue',
    wed: 'Wed',
    thu: 'Thu',
    fri: 'Fri',
    sat: 'Sat',
    sun: 'Sun',
  },
  candidatesHours: 'Candidate Hours',
  analytics: {
    notAllowed: 'This account does not have any analytics reports configured.',
    listOfAccessiblePages: 'Page List',
  },
  pagination: {
    showingResult: 'Showing {{currentItemsLength}} of {{totalItemsLength}} results',
    itemsPerPage: 'Items per page',
  },
};
